import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import MapVicio from '../components/MapVicio';
import gsap, { Expo, Power2 } from 'gsap';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import Instructions from '../layout/Instructions';

export default class End extends Page {
  constructor() {
    super();
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { 
          this.afterShow()
        });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }
}

ControllerPage._addPage('end', End);