import gsap, { Expo, Power2, Power4 } from 'gsap';
import { Draggable } from "gsap/Draggable";
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { Basics, isSmartphone } from '../_app/cuchillo/core/Basics';
import { InertiaPlugin } from 'gsap/all';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

export default class Instructions {
  static content = GetBy.class("__content", this.container)[0];
  static holder = GetBy.class("__holder", this.container)[0];
  static draggable;
  static posY = 0;
  static bg = GetBy.selector("[data-intructions]")[0];
  static info = GetBy.selector("[data-info]")[0];
  static isDispose = false;

  static init() {
    if (isSmartphone) {
      this.initDraggable();
    } else {
      this.bg.addEventListener(Basics.clickEvent,()=> {
        this.hide();
      })
    }
  }

  static show() {
   

    gsap.to(this.bg, {
      opacity: 1,
      duration: .6,
      delay: 1,
      ease: Power2.easeOut
    });

    gsap.to(this.info, {
      opacity: 1,
      duration: .4,
      delay: 1.5,
      ease: Power2.easeOut
    });

    gsap.from(this.info, {
      y: Metrics.CENTER_Y,
      duration: 1,
      delay: 1.5,
      ease: Expo.easeOut
    });

    if(this.draggable) this.draggable[0].enable();
  }

  static hide() {
    if(this.draggable) this.draggable[0].disable();

    const hideEffect = isSmartphone ? this.hide__mobile : this.hide__desktop;
    hideEffect.call(this);
  }

  static hide__desktop() {
    gsap.to(this.info, {
      y: Metrics.CENTER_Y,
      duration: .9,
      delay: 0,
      ease: Power2.easeIn
    });

    gsap.to(this.bg, {
      opacity: 0,
      duration: .4,
      delay: 0.3,
      ease: Power2.easeOut,
      onComplete:()=> {
        this.dispose();
      }
    });
  }

  static hide__mobile() {
    if(this.draggable) this.draggable[0].disable();

    gsap.to(this.bg, {
      y: Metrics.HEIGHT,
      duration: .5,
      delay: 0,
      ease: Power2.easeOut,
      onComplete: ()=> {
        this.dispose();
      }
    });

    gsap.to(this.info, {
      y: 0,
      duration: .5,
      delay: 0,
      ease: Power2.easeOut,
    });
  }

  static dispose() {
    if(this.isDispose) return;

    this.isDispose = true;

    if(this.draggable) this.draggable[0].disable();
    C.remove(this.bg);
  }

  static initDraggable() {
    if (isSmartphone) {
      this.draggable = Draggable.create(this.info, {
        type: 'y',
        inertia: true,
        edgeResistance: 0.5,
        bounds: { minY: -(this.info.offsetHeight - Metrics.HEIGHT), maxY: 0 },
        liveSnap: {
          y: (value) => {
              Instructions.posY = value;
              return Instructions.posY;
          }
        },
        onRelease: (e)=>{
          Instructions.hide();
        },
        onThrowUpdate: (e)=>{
          if(Instructions.posY > 50) {
            Instructions.hide();
          }
        }
      });
    }
  }
}
