import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";

export default class Preloader {
    _container;
    _progress;
    _progressHolder;
    _logo;

    static init () {
        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('__progress', this._container);
        this._progressHolder = GetBy.class('__progressHolder', this._container);
        this._logo = GetBy.class('__logo', this._container);
        //this._container.style.display = "none";
    }

    static update (__progress) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: __progress,
            ease: Power1.easeOut,
            duration: .3
        });
    }

    static show(__call) {
        gsap.to(this._logo, {
            duration: .7,
            opacity: 1,
            delay: 0
        });

        gsap.to(this._progressHolder, {
            duration: .4,
            opacity: 1,
            delay: 0.3,
            onComplete: () => {
                if(__call) __call();
            }
        });
    }

    static hide (__call) {
        gsap.killTweensOf(this._progress);
        gsap.to(this._progress, {
            scaleX: 1,
            ease: Power1.easeOut,
            duration: .3
        });

        gsap.to(this._progressHolder, {
            duration: .3,
            opacity: 0,
            delay: 0.3
        });

        gsap.to(this._logo, {
            duration: .7,
            fill: "#ff1e0b",
            opacity: 0,
            delay: 0.5
        });

        gsap.to(this._container, {
            duration: .4,
            opacity: 0,
            delay: 1.1,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });
        
    }
}
