import { C, GetBy } from "./_app/cuchillo/core/Element";

export default class DataHolder {

  static url = "https://www.cuchillo.tools/followyourvice/index.php?get-coordinates=true&city=";
  static cities = ["barcelona","madrid","sevilla","valencia","zaragoza","malaga"];
  static data = {
    barcelona:{},
    madrid:{},
    sevilla:{},
    valencia:{},
    zaragoza:{},
    malaga:{},
  }
  static time = 5000;
  static tick = 0;
  static firstcall;
  static onUpdate;
  static domHoras = {
    barcelona: GetBy.selector("[data-city='barcelona'")[0],
    madrid: GetBy.selector("[data-city='madrid'")[0],
    zaragoza: GetBy.selector("[data-city='zaragoza'")[0],
    valencia: GetBy.selector("[data-city='valencia'")[0],
    sevilla: GetBy.selector("[data-city='sevilla'")[0],
    malaga: GetBy.selector("[data-city='malaga'")[0],
  };
  static horas = {
    barcelona: [
      {hora:"18:50", value:"30"},
      {hora:"18:45", value:"28"},
      {hora:"18:30", value:"25"},
      {hora:"18:00", value:"22"},
      {hora:"17:55", value:"20"},
      {hora:"17:50", value:"19"},
      {hora:"17:45", value:"16"},
      {hora:"14:40", value:"13"},
      {hora:"14:23", value:"12"},
      {hora:"14:22", value:"11"},
      {hora:"14:20", value:"9"},
      {hora:"14:10", value:"8"},
      {hora:"14:00", value:"7"},
      {hora:"13:50", value:"5"},
      {hora:"13:20", value:"4"},
      {hora:"13:10", value:"3"},
      {hora:"13:00", value:"1"}, // Corregido de "3" a "1"
      {hora:"12:45", value:"1"}
    ],
    madrid: [
      {hora:"18:25", value:"29"},
      {hora:"18:10", value:"27"},
      {hora:"18:00", value:"24"},
      {hora:"17:45", value:"21"},
      {hora:"17:15", value:"19"},
      {hora:"17:11", value:"18"},
      {hora:"17:10", value:"15"},
      {hora:"14:45", value:"14"},
      {hora:"14:30", value:"11"},
      {hora:"14:18", value:"10"},
      {hora:"14:15", value:"8"},
      {hora:"14:05", value:"7"},
      {hora:"13:55", value:"6"},
      {hora:"13:45", value:"4"},
      {hora:"13:25", value:"5"},
      {hora:"13:15", value:"2"},
      {hora:"13:05", value:"2"},
      {hora:"12:50", value:"2"}
    ],
    zaragoza: [
      {hora:"18:25", value:"28"},
      {hora:"18:10", value:"26"},
      {hora:"18:00", value:"23"},
      {hora:"17:25", value:"20"},
      {hora:"17:10", value:"18"},
      {hora:"17:05", value:"17"},
      {hora:"17:00", value:"14"},
      {hora:"14:50", value:"13"},
      {hora:"14:28", value:"10"},
      {hora:"14:20", value:"9"},
      {hora:"14:15", value:"7"},
      {hora:"14:08", value:"6"},
      {hora:"13:58", value:"5"},
      {hora:"13:48", value:"3"},
      {hora:"13:28", value:"3"},
      {hora:"13:18", value:"2"},
      {hora:"13:08", value:"2"},
      {hora:"12:53", value:"1"}
    ],
    valencia: [
      {hora:"18:50", value:"29"},
      {hora:"18:40", value:"28"},
      {hora:"18:10", value:"24"},
      {hora:"18:00", value:"21"},
      {hora:"17:50", value:"18"},
      {hora:"17:40", value:"18"},
      {hora:"17:30", value:"12"},
      {hora:"14:42", value:"12"},
      {hora:"14:25", value:"8"},
      {hora:"14:12", value:"8"},
      {hora:"14:18", value:"8"},
      {hora:"14:07", value:"7"},
      {hora:"13:57", value:"6"},
      {hora:"13:47", value:"5"},
      {hora:"13:27", value:"4"},
      {hora:"13:17", value:"3"},
      {hora:"13:07", value:"2"},
      {hora:"12:52", value:"1"}
    ],
    sevilla: [
      {hora:"18:43", value:"29"},
      {hora:"18:33", value:"27"},
      {hora:"18:08", value:"24"},
      {hora:"17:48", value:"19"},
      {hora:"17:28", value:"16"},
      {hora:"17:08", value:"13"},
      {hora:"17:00", value:"11"},
      {hora:"14:48", value:"9"},
      {hora:"14:33", value:"9"},
      {hora:"14:20", value:"9"},
      {hora:"14:15", value:"9"},
      {hora:"14:05", value:"8"},
      {hora:"13:55", value:"7"},
      {hora:"13:45", value:"3"},
      {hora:"13:25", value:"3"},
      {hora:"13:15", value:"3"},
      {hora:"13:05", value:"3"},
      {hora:"12:50", value:"1"}
    ],
    malaga: [
      {hora:"18:50", value:"29"},
      {hora:"18:30", value:"27"},
      {hora:"18:00", value:"24"},
      {hora:"17:52", value:"21"},
      {hora:"17:32", value:"19"},
      {hora:"17:12", value:"18"},
      {hora:"17:57", value:"15"},
      {hora:"14:52", value:"14"},
      {hora:"14:37", value:"13"},
      {hora:"14:24", value:"8"},
      {hora:"14:19", value:"8"},
      {hora:"14:09", value:"8"},
      {hora:"13:59", value:"7"},
      {hora:"13:49", value:"6"},
      {hora:"13:29", value:"5"},
      {hora:"13:19", value:"4"},
      {hora:"13:09", value:"3"},
      {hora:"12:54", value:"2"}
    ]
  };
  

  static init(__call) {
    this.firstcall = __call;
    this.getData();
    this.setValues();
  }

  static setValues() {
    // Obtener la hora actual del navegador
    const horaActual = new Date().toLocaleTimeString('es-ES', { hour12: false });

    // Recorrer las ciudades
    for (const ciudad in this.horas) {
      if (this.horas.hasOwnProperty(ciudad)) {
        // Buscar el valor correspondiente a la hora actual en la ciudad actual
        const valoresCiudad = this.horas[ciudad];
        for (let i = 0; i < valoresCiudad.length; i++) {
          const horaCiudad = valoresCiudad[i].hora;

          // Comparar las horas (asumiendo que las horas están en formato HH:mm)
          if (horaCiudad <= horaActual) {
            console.log(`En ${ciudad} a las ${horaActual}, el valor es: ${valoresCiudad[i].value}`);
            console.log( this.domHoras[ciudad])
            this.domHoras[ciudad].textContent = valoresCiudad[i].value;
            break;
          }
        }
      }
    }

    setTimeout(()=> {this.setValues()}, 100000);
  }

  static getData() {
    let cont = 0;
    const onComplete = () => {
      cont++;
      
      if(cont == this.cities.length) {
        if(this.firstcall) {
          this.firstcall();
          this.firstcall = null;
        }

        if(this.onUpdate){
          this.onUpdate(this.data);
        }

        setTimeout(() => { this.getData(); }, this.time);
      }
    };

    this.cities.forEach(city => {
      //if(new Date().getHours() < 12 || (new Date().getHours() === 12 && new Date().getMinutes() < 30)) {
        this.data = {
          barcelona:{latitude:"25.769375919273962",longitude:"-80.19981464377355"},
          madrid:{latitude:"35.67561415074176",longitude:"139.775023633314"},
          sevilla:{latitude:"37.55967625830442",longitude:"126.97456646322655"},
          valencia:{latitude:"47.916275714183854",longitude:"106.90632877091646"},
          zaragoza:{latitude:"62.02362243588306",longitude:"-6.837177858220912"},
          malaga:{latitude:"-78.95946029344309",longitude:"46.05468635922855"}
        }
        onComplete();
        onComplete();
        onComplete();
        onComplete();
        onComplete();
        onComplete();
      /*} else {
        const xhr = new XMLHttpRequest();

        const timestamp = new Date().getTime();
        xhr.open("GET", `${this.url}${city}&timestamp=${timestamp}`, true);
        

        xhr.onreadystatechange = () => {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              const data = JSON.parse(xhr.responseText);
              this.data[city] = data;
            } else {
              console.error(`Error al obtener datos para ${city}. Estado HTTP: ${xhr.status}`);
            }
          }

          onComplete();
        };
        xhr.send();
      }*/
    });

    this.tick++;
  }
}
