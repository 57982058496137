import gsap, { Expo, Power2, Power4 } from 'gsap';
import { Draggable } from "gsap/Draggable";
import _Sidemenu from '../_app/cuchillo/layout/Sidemenu';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import { GetBy } from '../_app/cuchillo/core/Element';
import { isSmartphone } from '../_app/cuchillo/core/Basics';
import { InertiaPlugin } from 'gsap/all';

gsap.registerPlugin(Draggable);
gsap.registerPlugin(InertiaPlugin);

export default class Sidemenu extends _Sidemenu {
  static content = GetBy.class("__content", this.container)[0];
  static holder = GetBy.class("__holder", this.container)[0];
  static draggable;
  static posY = 0;

  static init() {
    super.init();
    this.initDraggable();
  }

  static show__effect() {
    const showEffect = isSmartphone ? this.show__effect_mobile : this.show__effect_desktop;
    showEffect.call(this);
  }

  static hide__effect() {
    if(this.draggable) this.draggable[0].disable();

    const hideEffect = isSmartphone ? this.hide__effect_mobile : this.hide__effect_desktop;
    hideEffect.call(this);
  }

  static initDraggable() {
    if (isSmartphone) {
      this.draggable = Draggable.create(this.holder, {
        type: 'y',
        inertia: true,
        edgeResistance: 0.5,
        bounds: { minY: -(this.holder.offsetHeight - Metrics.HEIGHT), maxY: 0 },
        liveSnap: {
          y: (value) => {
              Sidemenu.posY = value;
              return Sidemenu.posY;
          }
        },
        
        onThrowUpdate: (e)=>{
          if(Sidemenu.posY > 50) {
            Sidemenu.hide();
          }
        }
      });
    }
  }


  static show__effect_desktop() {
    gsap.set(this.container,{alpha:1})
    gsap.set(this.content,{x:Metrics.CENTER_X})
    gsap.set(this.holder,{x:Metrics.CENTER_X * -.3})
    
    gsap.to(this.content, {
      x: 0,
      duration: .8,
      delay: 0,
      ease: Expo.easeOut,
      onComplete: ()=> {
        this.afterShow();
      }
    });

    gsap.to(this.holder, {
      x: 0,
      duration: .9,
      delay: 0,
      ease: Expo.easeOut
    });
  }

  static show__effect_mobile() {
    gsap.set(this.container,{alpha:1})
    gsap.set(this.content,{y:Metrics.HEIGHT})
    gsap.set(this.holder,{y:Metrics.HEIGHT * .4})
    
    gsap.to(this.content, {
      y: 0,
      duration: .8,
      delay: 0,
      ease: Expo.easeOut,
      onComplete: ()=> {
        this.afterShow();
        if(this.draggable) this.draggable[0].enable();
      }
    });

    gsap.to(this.holder, {
      y: 0,
      duration: .9,
      delay: 0,
      ease: Expo.easeOut
    });
  }

  static hide__effect_desktop() {
    gsap.to(this.content, {
      x: Metrics.CENTER_X,
      duration: .4,
      delay: 0,
      ease: Power2.easeIn,
      onComplete: ()=> {
        this.afterHide();
      }
    });

    gsap.to(this.holder, {
      x: Metrics.CENTER_X * -.3,
      duration: .4,
      delay: 0,
      ease: Power2.easeIn,
    });
  }

  static hide__effect_mobile() {
    gsap.to(this.content, {
      y: Metrics.HEIGHT,
      duration: .5,
      delay: 0,
      ease: Power2.easeOut,
      onComplete: ()=> {
        this.afterHide();
      }
    });

    gsap.to(this.holder, {
      y: 0,
      duration: .5,
      delay: 0,
      ease: Power2.easeOut,
    });
  }
}
