import { Acordions } from '../_app/cuchillo/components/Acordions';
import { Basics } from '../_app/cuchillo/core/Basics';
import { ControllerPage } from '../_app/cuchillo/pages/ControllerPage';
import Page from '../_app/cuchillo/pages/Page';
import Preloader from '../layout/Preloader';
import Wrap from '../layout/Wrap';
import Keyboard from '../_app/cuchillo/core/Keyboard';
import MapVicio from '../components/MapVicio';
import gsap, { Expo, Power2 } from 'gsap';
import { C, GetBy } from '../_app/cuchillo/core/Element';
import { Metrics } from '../_app/cuchillo/core/Metrics';
import Instructions from '../layout/Instructions';

export default class Default extends Page {
  constructor() {
    super();

    Instructions.init();
    MapVicio.init();
  }

  setupMap() {
    
  }

  //SHOW
  beforeShow() {
    super.beforeShow();
    Keyboard.mountPage(this.id);
  }

  show__effect(__call) {
    if (this.isFirstTime) {
      Wrap.show(() => {
        Preloader.hide(() => { 
          MapVicio.show();
          Instructions.show();
          this.afterShow()
        });
      });
    } else {
      Wrap.show(() => { this.afterShow() });
    }
  }

  showIntructions() {
    const bg = GetBy.selector("[data-intructions]")[0];
    const info = GetBy.selector("[data-info]")[0];

    gsap.to(bg, {
      opacity: 1,
      duration: .6,
      delay: 1,
      ease: Power2.easeOut
    });

    gsap.to(info, {
      opacity: 1,
      duration: .4,
      delay: 1.5,
      ease: Power2.easeOut
    });

    gsap.from(info, {
      y: Metrics.CENTER_Y,
      duration: 1,
      delay: 1.5,
      ease: Expo.easeOut
    });

    bg.addEventListener(Basics.clickEvent, ()=> {
      gsap.to(info, {
        y: Metrics.CENTER_Y,
        duration: .9,
        delay: 0,
        ease: Power2.easeIn
      });

      gsap.to(bg, {
        opacity: 0,
        duration: .4,
        delay: 0.3,
        ease: Power2.easeOut,
        onComplete:()=> {
          C.remove(bg);
        }
      });
    });
  }

  afterShow() {
    super.afterShow();
  }

  //HIDE
  beforeHide() {
    Keyboard.unmountPage(this.id);
    super.beforeHide();
  }

  hide__effect() {
    Wrap.hide(() => { this.afterHide(); });
  }

  afterHide() {
    super.afterHide();
  }

  //RESIZE
  resize() {
    super.resize();
    Acordions.resize();
  }

  //LOOP
  loop() {
    if (this._isActive) {
      super.loop();
    }
  }
}

ControllerPage._addPage('default', Default);