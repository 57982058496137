import mapboxgl from "mapbox-gl";
import DataHolder from "../DataHolder";
import { GetBy } from "../_app/cuchillo/core/Element";
import { Basics, isSmartphone } from "../_app/cuchillo/core/Basics";

export default class MapVicio {
  static map;
  static me;
  static markers = {}
  static marker = "/assets/images/marker-ambulance.png";

  static init() {
    mapboxgl.accessToken = 'pk.eyJ1IjoibXJjb3JyYWxlcyIsImEiOiJjbHFiMWtkNjkxdmMyMmxudjNueThrdmtpIn0.9jMqB335b7UZEEAoOq7Grw';
    this.map = new mapboxgl.Map({
      container: 'MapboxHolder', // container ID
      style: 'mapbox://styles/coko-dosis/clq3ygodz01wv01pjg143ckzo', // style URL
      center: [-122.078827,37.419857],
      zoom: 1
    });

    this.setupMe();
    this.setupMarkers(DataHolder.data);
    DataHolder.onUpdate = (data) => this.updateMarkers(data);

    GetBy.selector("[data-center]")[0].addEventListener(Basics.clickEvent, (e)=> {MapVicio.center();});
  }

  static show() {
    this.map.flyTo({
      center: [-3.7026362,40.4160307],
      zoom: isSmartphone? 4.2 : 5.4,
      duration: 5000, // Animate over 12 seconds
      essential: true // This animation is considered essential with
      //respect to prefers-reduced-motion
      });
  }

  static center() {
    this.map.flyTo({
      center: [this.me._lngLat.lng, this.me._lngLat.lat],
      zoom: 19,
      duration: 5000, // Animate over 12 seconds
      essential: true // This animation is considered essential with
      //respect to prefers-reduced-motion
      });
  }

  static setupMe() {
    const el = document.createElement('div');
    el.className = 'marker-me';
    //el.style.backgroundImage = `url('/assets/images/marker-me.png')`

    const marker = new mapboxgl.Marker(el)
      .setLngLat([0, 0])
      .addTo(this.map);

    this.me = marker;

    const options = {
        enableHighAccuracy: false,
        timeout: 9800,
        maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition((pos)=> {
      this.me.setLngLat([pos.coords.longitude, pos.coords.latitude]);
    }, 
    (e)=>{console.log(e)}, 
    options);
  }

  static setupMarkers(data) {
    // Agregar nuevos marcadores
    Object.keys(data).forEach(city => {
      const { latitude, longitude } = data[city];

      const el = document.createElement('div');
      el.className = 'marker-ambulance';
      el.style.backgroundImage = `url('/assets/images/marker-ambulance.png')`

      const marker = new mapboxgl.Marker(el)
        .setLngLat([longitude, latitude])
        .addTo(this.map);

      this.markers[city] = marker;
    });
  }

  static updateMarkers(data) {
    Object.keys(data).forEach(city => {
      const { latitude, longitude } = data[city];      
      this.markers[city].setLngLat([longitude, latitude]);
    });

    const options = {
        enableHighAccuracy: false,
        timeout: 9800,
        maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition((pos)=> {
      this.me.setLngLat([pos.coords.longitude, pos.coords.latitude]);
    }, 
    (e)=>{console.log(e)}, 
    options);
  }
}